@import "./../../style-config/colors";
@import "./../../style-config/general";

.carbon-navigation-bar {
  min-height: $app-navigation-height;

  .carbon-logo {
    margin-right: 10px;
  }
}

.carbon-navigation-bar--primary {
  background-color: $white;
}

.carbon-navigation-bar--secondary {
  background-color: $grey-dark-blue;
  color: $white;
}

.carbon-navigation-bar__content {
  line-height: $app-navigation-height;
}

.carbon-navigation-bar__content > * {
  box-sizing: border-box;
  display: inline-block;
  height: $app-navigation-height;
  vertical-align: middle;
}
