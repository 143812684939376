@import "./../../../style-config/colors";
@import "./../../../style-config/mixins";
@import "./../../../style-config/z-index";

.carbon-menu-item.carbon-link__anchor,
.carbon-menu-item {
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  height: 40px;
  padding: 0px 24px;
  position: relative;

  .carbon-portrait {
    top: -1px;
  }

  &:focus {
    z-index: 1;
  }

  .carbon-menu--primary &,
  .carbon-menu--primary &:hover {
    background-color: $white;
    color: $grey-dark-blue;

    &.carbon-link__anchor:hover {
      &:not(.carbon-menu-item--has-link) {
        cursor: initial;
      }

      .carbon-link__content {
        text-decoration: none;
      }
    }

    .carbon-menu-item__submenu .carbon-menu-item--has-link:hover {
      background: $blue;
      cursor: pointer;
      color: white;
    }

    &:after {
      @include transition(all 200ms);
      content: "";
      position: absolute;
      height: 0;
      background-color: transparent;
      bottom: 0;
      left: 0%;
      right: 0%;
    }

    &:hover:after {
      background-color: $blue-bright;
      height: 3px;
      left: 0% !important;
      right: 0% !important;
    }

    &.carbon-menu-item--selected:after {
      left: 10px;
      right: 10px;
      background-color: $green-bright;
      height: 3px;
    }
  }

  .carbon-menu--secondary &,
  .carbon-menu--secondary &:hover {
    background-color: $grey-dark-blue;
    color: $white;

    &.carbon-link__anchor:hover {
      &:not(.carbon-menu-item--has-link) {
        cursor: initial;
      }

      .carbon-link__content {
        text-decoration: none;
      }
    }

    &:hover {
      background-color: $grey-dark-plus;
    }

    &.carbon-menu-item--has-link:hover {
      cursor: pointer;
    }
  }

  .carbon-menu-item:after {
    display: none;
  }

  &.carbon-menu-item--has-submenu {
    .carbon-menu-item__submenu-title {
      background-color: transparent;
    }
  }
}

.carbon-menu-item--has-submenu {
  padding: 0;

  &:before {
    pointer-events: none;
    right: 15px;
    top: 50%;
    margin-top: -2px;
    position: absolute;
    z-index: 2;

    .carbon-menu--primary & {
      @include arrow("down", 4px, $grey-dark-blue);
    }

    .carbon-menu--secondary & {
      @include arrow("down", 4px, $white);
      right: 14px;
    }
  }

  &:hover .carbon-menu-item__submenu {
    display: block;
  }
}

.carbon-menu-item__submenu {
  box-shadow: 0 15px 25px rgba($grey-shadow, 0.15);
  display: none;
  list-style: none;
  margin: 0;
  min-width: 100%;
  padding: 0;
  position: absolute;
  z-index: $z-dropdown-list;

  .carbon-icon {
    line-height: 16px;
    margin: 0 10px 0 0;
    top: -1px;

    &:before {
      line-height: unset;
    }

    .carbon-icon__svg-icon {
      vertical-align: middle;

      .carbon-icon__svg {
        height: 16px;
        width: 16px;
      }
    }
  }

  .carbon-menu--primary & {
    background-color: $white;

    .carbon-menu-item--selected {
      color: $green-bright-dull;
    }
  }

  .carbon-menu--secondary & {
    background-color: $grey-dark-plus;

    .carbon-icon {
      margin: 0 15px 0 0;
    }
  }

  &:before {
    background-color: transparent;
    border-radius: 0 0 4px 4px;
    content: "";
    height: 5px;
    position: absolute;
    top: -5px;
    width: 100%;
  }

  > *:not(.carbon-menu-item__submenu-item) {
    padding: 8px 15px 10px;

    .carbon-menu--primary & {
      background-color: $white;
    }

    .carbon-menu--secondary & {
      background-color: $grey-dark-plus;
    }
  }

  .carbon-menu-item {
    display: block;
    height: 40px;
    line-height: 40px;
    white-space: nowrap;

    .carbon-menu--primary & {
      background-color: $white;
    }

    .carbon-menu--secondary & {
      background-color: $grey-dark-plus;
      padding: 0 15px;
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid $grey-dark-plus;

      &.carbon-menu-item--has-link:hover {
        background-color: $grey-dark-blue-90;
      }
    }
  }
}

.carbon-menu-item__submenu-item {
  margin: 0;
  padding: 0;
}

.carbon-menu-item__submenu--left {
  right: 0;
}

.carbon-menu-item__submenu-title.carbon-link__anchor {
  padding-right: 26px;
}

.carbon-menu-item--divide {
  margin-top: 10px;

  &:before {
    content: "";
    height: 1px;
    left: 12px;
    right: 12px;
    top: -5px;
    position: absolute;

    .carbon-menu--primary & {
      background-color: $grey-dark-blue;
    }

    .carbon-menu--secondary & {
      background-color: $white;
      left: 15px;
      right: 15px;
    }
  }
}


