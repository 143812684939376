@import "./../../style-config/general";

.carbon-app-wrapper {
  max-width: $app-max-width;
  min-width: $app-width;
  margin: 0 auto;
  padding: 0 40px;

  @media only screen and (max-width: 1366px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 1024px) {
    padding: 0 25px;
  }
}
