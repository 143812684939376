// Red
$red:                        #D63F40 !default;
$red-light:                  #E42C2D !default;
$red-dark:                   #C11E20 !default;
$red-05:                     #FDF5F5 !default;
$red-20:                     #F7D9D9 !default;

// Orange
$beta:                       #FF5400 !default;
$orange-bright:              #FF7D00 !default;
$orange-bright-05:           #FFF8F2 !default;
$orange-bright-20:           #FFE4CC !default;

// Yellow
$yellow:                     #FFAB00 !default;
$yellow-05:                  #FFFBF2 !default;
$yellow-20:                  #FFEECC !default;

// Green
$green-bright:               #00DC00 !default;
$green-apple:                #50B848 !default;
$green-apple-05:             #F6FBF6 !default;
$green-apple-20:             #DCF1DA !default;
$green-bright-dull:          #38C72A !default;

// Blue
$blue:                       #255BC7 !default;
$blue-bright:                #1963F6 !default;
$blue-sky:                   #1573E6 !default;
$blue-sky-05:                #F3F8FE !default;
$blue-sky-10:                #E7F1FC !default;
$blue-sky-20:                #D0E3FA !default;
$blue-light:                 #4782F7 !default;
$azul:                       #004089 !default;

// Purple
$purple:                     #663399 !default;
$purple-05:                  #F7F5FA !default;
$purple-20:                  #E0D6EB !default;

// Magenta
$magenta:                    #ED1C5F !default;
$magenta-dull:               #CA2A60 !default;
$magenta-light:              #F32E6D !default;

// New Colors
// GREEN
$green-brilliant: #00DC00;

// Segment - Small
$segment-small: #00A376;

// Segment - Medium
$segment-medium: #0077C8;

// Segment - Large
$segment-large: #582C83;

// GOLD
$gold: #FFB500;

// RED
$red: #C7384F;

// SLATE
$slate: #003349;

// AZUL
$azul: #004B87;

// PLUM
$plum: #8246AF;

// FUCHSIA
$fuchsia: #CE0058;

// Slate Tints
$slate-tint-98:          mix(white, $slate, 98) !default; // #FAFBFB
$slate-tint-95:          mix(white, $slate, 95) !default; // #F2F4F5
$slate-tint-90:          mix(white, $slate, 90) !default; // #E6EBED
$slate-tint-85:          mix(white, $slate, 85) !default; // #D8DFE3
$slate-tint-80:          mix(white, $slate, 80) !default; // #CCD6DA
$slate-tint-75:          mix(white, $slate, 75) !default; // #BFCBD1
$slate-tint-70:          mix(white, $slate, 70) !default; // #B3C2C8
$slate-tint-60:          mix(white, $slate, 60) !default; // #99ADB6
$slate-tint-50:          mix(white, $slate, 50) !default; // #7F98A3
$slate-tint-40:          mix(white, $slate, 40) !default; // #668491
$slate-tint-30:          mix(white, $slate, 30) !default; // #4C6F7F
$slate-tint-25:          mix(white, $slate, 25) !default; // #3F6575
$slate-tint-20:          mix(white, $slate, 20) !default; // #335B6D
$slate-tint-15:          mix(white, $slate, 15) !default; // #275264
$slate-tint-10:          mix(white, $slate, 10) !default; // #19475A
$slate-tint-5:           mix(white, $slate, 5)  !default; // #0D3D52

// Slate Shades
$slate-shade-90:          mix(black, $slate, 90) !default; // #000507 
$slate-shade-80:          mix(black, $slate, 80) !default; // #000A0E
$slate-shade-70:          mix(black, $slate, 70) !default; // #000F15
$slate-shade-60:          mix(black, $slate, 60) !default; // #00141D 
$slate-shade-50:          mix(black, $slate, 50) !default; // #001924
$slate-shade-40:          mix(black, $slate, 40) !default; // #001E2B
$slate-shade-30:          mix(black, $slate, 30) !default; // #002333
$slate-shade-20:          mix(black, $slate, 20) !default; // #00283A
$slate-shade-10:          mix(black, $slate, 10) !default; // #002E41

// Shadows
$card: 0 3px 3px 0 rgba($slate-shade-60,0.2), 0 2px 4px 0 rgba($slate-shade-60,0.15);

$shadow-depth-1: 0 5px 5px 0 rgba($slate-shade-60,0.2), 0 10px 10px 0 rgba($slate-shade-60,0.1);
$shadow-depth-2: 0 10px 20px 0 rgba($slate-shade-60,0.2), 0 20px 40px 0 rgba($slate-shade-60,0.1);
$shadow-depth-3: 0 10px 30px 0 rgba($slate-shade-60,0.1), 0 30px 60px 0 rgba($slate-shade-60,0.1);
$shadow-depth-4: 0 10px 40px 0 rgba($slate-shade-60,0.04), 0 50px 80px 0 rgba($slate-shade-60,0.1);

$grey-dark-blue:             $slate         !default;
$grey-dark-blue-90:          $slate-tint-10 !default;
$grey-dark-blue-80:          $slate-tint-20 !default;
$grey-dark-blue-70:          $slate-tint-30 !default;
$grey-dark-blue-60:          $slate-tint-40 !default;
$grey-dark-blue-50:          $slate-tint-50 !default;
$grey-dark-blue-40:          $slate-tint-60 !default;
$grey-dark-blue-30:          $slate-tint-70 !default;
$grey-dark-blue-25:          $slate-tint-75 !default;
$grey-dark-blue-20:          $slate-tint-80 !default;
$grey-dark-blue-15:          $slate-tint-85 !default;
$grey-dark-blue-10:          $slate-tint-90 !default;
$grey-dark-blue-5:           $slate-tint-95 !default;

// Grey
$grey-shadow:                #021224 !default;
$grey-dark-plus:             #1B1D21 !default;
$dark-blue-plus:             #262A33 !default;
