@charset "UTF-8";
/**
 * Color and Icon sets used for components with themes.
 */
.carbon-icon {
  display: inline-block;
  position: relative; }

.carbon-icon__svg-icon {
  display: inline-block; }

.carbon-icon__svg {
  fill: currentColor; }

.carbon-icon--shape {
  align-items: center;
  display: inline-flex;
  justify-content: center; }

.carbon-icon--rounded-rect {
  border-radius: 20%; }

.carbon-icon--circle {
  border-radius: 50%; }

.carbon-icon--warning {
  background-color: #FF7D00; }

.carbon-icon--default {
  background-color: #335c6d; }

.carbon-icon--error {
  background-color: #C7384F; }

.carbon-icon--info {
  background-color: #1573E6; }

.carbon-icon--new {
  background-color: #663399; }

.carbon-icon--success {
  background-color: #50B848; }

.carbon-icon--help {
  background-color: #FFAB00; }

.carbon-icon--maintenance {
  background-color: #FF7D00; }

.carbon-icon--small {
  height: 24px;
  width: 24px; }

.carbon-icon--medium {
  height: 32px;
  width: 32px; }

.carbon-icon--large {
  height: 40px;
  width: 40px; }

@font-face {
  font-family: 'CarbonIcons';
  src: url(fonts/carbon-icons-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: CarbonIcons;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  vertical-align: middle; }

.icon-feedback:before {
  content: ""; }

.icon-info:before {
  content: ""; }

.icon-help:before {
  content: ""; }

.icon-settings:before {
  content: ""; }

.icon-logout:before {
  content: ""; }

.icon-pdf:before {
  -webkit-font-smoothing: none;
  content: ""; }

.icon-csv:before {
  -webkit-font-smoothing: none;
  content: ""; }

.icon-copy:before {
  -webkit-font-smoothing: none;
  content: ""; }

.icon-error:before {
  content: ""; }

.icon-calendar:before {
  -webkit-font-smoothing: none;
  content: ""; }

.icon-analysis:before {
  content: ""; }

.icon-people:before {
  content: ""; }

.icon-dropdown:before {
  content: ""; }

.icon-alert:before {
  content: ""; }

.icon-person:before {
  content: ""; }

.icon-search:before {
  content: ""; }

.icon-cross:before {
  content: ""; }

.icon-close:before {
  content: ""; }

.icon-delete:before {
  content: ""; }

.icon-message:before {
  content: ""; }

.icon-messages:before {
  content: ""; }

.icon-email:before {
  content: ""; }

.icon-edit:before {
  content: ""; }

.icon-print:before {
  content: ""; }

.icon-clock:before {
  content: ""; }

.icon-euro:before {
  content: "t"; }

.icon-home:before {
  content: ""; }

.icon-cart:before {
  content: ""; }

.icon-bulk_destroy:before {
  content: ""; }

.icon-hide:before {
  content: "w"; }

.icon-warning:before {
  content: ""; }

.icon-question:before {
  content: ""; }

.icon-question_hollow:before {
  content: ""; }

.icon-tick:before {
  content: ""; }

.icon-none:before {
  content: " "; }

.icon-add:before {
  content: ""; }

.icon-services:before {
  content: "+"; }

.icon-collaborate:before {
  content: ""; }

.icon-graph:before {
  content: ""; }

.icon-plus:before {
  content: ""; }

.icon-true _tick:before {
  content: ""; }

.icon-arrow:before {
  content: ""; }

.icon-old_warning:before {
  content: ""; }

.icon-pound:before {
  content: "£"; }

.icon-settings_old:before {
  content: ""; }

.icon-go:before {
  content: ""; }

.icon-question_mark:before {
  content: ""; }

.icon-admin:before {
  content: ""; }

.icon-contacts:before {
  content: ""; }

.icon-create:before {
  content: ""; }

.icon-piggy_bank:before {
  content: ""; }

.icon-entry:before {
  content: ""; }

.icon-edited:before {
  content: ""; }

.icon-refresh:before {
  content: ""; }

.icon-sync:before {
  content: ""; }

.icon-fax:before {
  -webkit-font-smoothing: none;
  content: ""; }

.icon-shop:before {
  content: ""; }

.icon-attach:before {
  content: ""; }

.icon-filter:before {
  content: ""; }

.icon-chat:before {
  content: ""; }

.icon-duplicate:before {
  -webkit-font-smoothing: none;
  content: ""; }

.icon-call:before {
  content: ""; }

.icon-phone:before {
  content: ""; }

.icon-favourite:before {
  content: ""; }

.icon-favourite_lined:before {
  content: ""; }

.icon-sort_up:before {
  content: ""; }

.icon-sort_down:before {
  content: ""; }

.icon-link:before {
  content: ""; }

.icon-locked:before {
  content: ""; }

.icon-unlocked:before {
  content: ""; }

.icon-caret_down:before {
  content: ""; }

.icon-draft:before {
  content: ""; }

.icon-chart_line:before {
  content: ""; }

.icon-chart_bar:before {
  content: ""; }

.icon-chart_pie:before {
  content: ""; }

.icon-in_progress:before {
  content: ""; }

.icon-progressed:before {
  content: ""; }

.icon-save:before {
  content: ""; }

.icon-image:before {
  content: ""; }

.icon-camera:before {
  content: ""; }

.icon-arrow_up:before {
  content: ""; }

.icon-arrow_down:before {
  content: ""; }

.icon-arrow_left:before {
  content: ""; }

.icon-arrow_right:before {
  content: ""; }

.icon-chevron_up:before {
  content: ""; }

.icon-chevron_down:before {
  content: ""; }

.icon-chevron_left:before {
  content: ""; }

.icon-chevron_right:before {
  content: ""; }

.icon-download:before {
  content: ""; }

.icon-upload:before {
  content: ""; }

.icon-uploaded:before {
  content: ""; }

.icon-folder:before {
  content: ""; }

.icon-share:before {
  content: ""; }

.icon-gift:before {
  content: ""; }

.icon-mobile:before {
  content: ""; }

.icon-grid:before {
  content: ""; }

.icon-fit_height:before {
  content: ""; }

.icon-fit_width:before {
  content: ""; }

.icon-blocked:before {
  content: ""; }

.icon-blocked_square:before {
  content: ""; }

.icon-drag:before {
  content: ""; }

.icon-drag_vertical:before {
  content: ""; }

.icon-list_view:before {
  content: ""; }

.icon-card_view:before {
  content: ""; }

.icon-minus:before {
  content: ""; }

.icon-business:before {
  content: ""; }

.icon-key:before {
  content: ""; }

.icon-credit_card:before {
  content: ""; }

.icon-marker:before {
  content: ""; }

.icon-delivery:before {
  content: ""; }

.icon-filter_new:before {
  content: ""; }

.icon-view:before {
  content: ""; }

.icon-disputed:before {
  content: ""; }

.icon-connect:before {
  content: ""; }

.icon-chat_notes:before {
  content: ""; }

.icon-talk:before {
  content: ""; }

.icon-split:before {
  content: ""; }

.icon-disconnect:before {
  content: ""; }

.icon-bullet_list:before {
  content: ""; }

.icon-lightbulb_on:before {
  content: ""; }

.icon-lightbulb_off:before {
  content: ""; }

.icon-accounts:before {
  content: "1"; }

.icon-accounts_extra:before {
  content: "1"; }

.icon-cashbook:before {
  content: "1"; }

.icon-payroll:before {
  content: "2"; }

.icon-insert_row:before {
  content: "A"; }

.icon-book:before {
  content: "D"; }

.icon-bank:before {
  content: "G"; }

.icon-new:before {
  content: "k"; }

.icon-video:before {
  content: ""; }

.icon-play:before {
  content: ""; }

.icon-ellipsis_horizontal:before {
  content: ""; }

.icon-ellipsis_vertical:before {
  content: ""; }
