@import "./../../style-config/colors";

$link-color: $blue !default;
$link-hover-color: $blue-dark !default;
$link-hover-text-decoration: underline !default;

.carbon-link__anchor {
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;

  &:not(.carbon-button) {
    color: $link-color;
  }

  &:hover {
    cursor: pointer;
    text-decoration: none;

    &:not(.carbon-button) {
      color: $link-hover-color;

      .carbon-link__content {
        text-decoration: $link-hover-text-decoration;
      }
    }
  }
}

.carbon-link__anchor--disabled {
  color: $grey-dark;
  cursor: default;
  pointer-events: none;
  text-decoration: none;

  &:hover {
    color: $grey-dark;
    cursor: default;

    .carbon-link__content {
      text-decoration: none;
    }
  }
}

.carbon-link__icon {
  margin-right: 5px;
  position: relative;
  top: -2px;

  .carbon-icon__svg {
    height: 16px;
    vertical-align: middle;
    width: 16px;
  }
}

.carbon-link__icon--align-right {
  margin-right: 0;
  margin-left: 5px;
}
